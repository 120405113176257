import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyBvJNNeC7mPtT0MUClx2JFx8ei_lz67Y5o",
    authDomain: "opta-momentum.firebaseapp.com",
    projectId: "opta-momentum",
    storageBucket: "opta-momentum.appspot.com",
    messagingSenderId: "1069976892032",
    appId: "1:1069976892032:web:2f3d73dc5b7c8cb694ac65",
    measurementId: "G-SL77NR0JP8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireauth = getAuth(app);
const firedb = getFirestore();
const analytics = getAnalytics(app);
const fireperf = getPerformance(app);

setPersistence(fireauth, browserLocalPersistence);

export { fireauth, firedb, analytics, fireperf };
