<template>
	<div class="layout-topbar">
		<div class="layout-topbar-left">
			<router-link to="/" class="app-name">
				<img alt="Momentum Campaigns Button Generator by Optakit" class="app-logo" :src="'layout/images/LogoInverted.png'">
				<!-- <span>Button Generator</span> -->
			</router-link>

			<!-- <a href="#" class="menu-button" @click="onMenuButtonClick">
				<div class="menu-button-icon"></div>
			</a> -->

			<!-- <ul class="layout-topbar-main-menu">
				<li>
					<router-link to="/" exact-active-class="topbar-active-menuitem-routerlink">Sales
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li>
					<router-link to="/analytics" exact-active-class="topbar-active-menuitem-routerlink">Analytics
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li>
					<router-link to="/blocks" active-class="topbar-active-menuitem-routerlink">Prime Blocks
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li :class="['layout-topbar-menu', {'topbar-active-menuitem': topbarMenuActive}]">
					<a v-if="!menu[0].to" :style="menu[0].style" :class="[menu[0].class, 'p-ripple', {'p-disabled': menu[0].disabled}]" :target="menu[0].target"
						@click="onMenuItemClick($event,menu[0])" v-ripple>
						<i :class="['layout-menuitem-icon', menu[0].icon]"></i>
						<span class="layout-menuitem-text">{{menu[0].label}}</span>
						<i v-if="menu[0].items" class="pi pi-fw pi-angle-down layout-topbar-submenu-toggler"></i>
					</a>
					<AppTopbarMenu :items="menu[0].items" v-if="topbarMenuActive" :topbarMenuActive="topbarMenuActive" :parentMenuItemActive="true"
						@topbar-submenu-click="onTopbarMenuClick($event)" @topbar-submenuitem-click="onTopbarSubmenuItemClick($event)"></AppTopbarMenu>
				</li>
			</ul> -->
		</div>

		<div class="layout-topbar-right">

			<ul class="layout-topbar-actions">
				<!-- <li :class="['search-item', {'active-topmenuitem': activeTopbarItem === 'search'}]">
					<a tabindex="0" @click="onTopbarItemClick($event, 'search')">
						<i class="pi pi-search"></i>
					</a>

					<div class="search-input-wrapper">
						<span class="p-input-icon-right">
							<InputText ref="input1" type="text" placeholder="Type your keywords" @click="$emit('search-item-click');"/>
							<i class="pi pi-search"></i>
						</span>
					</div>

					<ul class="fadeInDown">
						<div class="search-input-wrapper p-fluid" style="width: 100%">
							<span class="p-input-icon-right">
								<InputText ref="input2" type="text" placeholder="Type your keywords" @click="$emit('search-item-click');" />
								<i class="pi pi-search"></i>
							</span>
						</div>
					</ul>
				</li> -->

				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
					<div class="profile-container" @click="onTopbarItemClick($event, 'profile')">
						<a href="#"><i class="pi pi-user" style="color: #19536C; display: initial !important;"></i></a>
						<div class="name-container">
							<span class="name">{{ display_name ? display_name : "User" }}</span>
						</div>
						<i class="pi pi-angle-down"></i>
					</div>
					
					<ul class="profile-menu fadeInDown">
						<!-- <li>
                            <router-link to="/settings">
                                <span>Settings</span>
                            </router-link>
						</li> -->
                        <li>
							<a href="#" @click="userSignOut">
								<span>Sign Out</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>			
	</div>
</template>

<script>
// import AppTopbarMenu from './AppTopbarMenu';
import { fireauth, firedb } from "@/firebase";
import {
    signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export default {
	emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click'],
	props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
            display_name: null,
			menu: [
				
			]
		}
	},
	methods: {
        goLogin() {
            this.$router.push({ path: '/login' });
        },
        userSignOut() {
        signOut(fireauth)
            .then(() => {
            setTimeout(() => {
                this.goLogin();
            }, 500);
            })
            .catch((error) => {
            console.log(error);
            });
        },
        async getUser(n=5) {
            if (fireauth.currentUser) {
                let docSnap = await getDoc(doc(firedb, "user", fireauth.currentUser.uid))
                if (docSnap.exists()) {
                    this.display_name = docSnap.data().display_name;
                    if (docSnap.data().user_email.includes("@momentumcampaigns.com") || docSnap.data().user_email.includes("@optakit.com")) {
                        this.isStaff = true;
                    }
                } else {
                    setTimeout(() => {
                        n--;
                        this.getUser(n);
                    }, 1000)
                }
            }
            else {
                setTimeout(() => {
                    n--;
                    this.getUser(n);
                }, 1000)
            }
        },
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onTopbarItemClick(event, item) {
			if(item === 'search' && this.$refs.input1) {
				setTimeout(() => {
					this.$refs.input1.$el.focus();
					this.$refs.input2.$el.focus();
				}, 400);
			}

			this.$emit('topbar-item-click', {originalEvent: event, item: item});
			event.preventDefault();
		},
		onTopbarMenuClick(event) {
			this.$emit('topbar-submenu-click', event);
		},
		onTopbarSubItemClick(event) {
			event.preventDefault();
		},
		onTopbarSubmenuItemClick(event) {
			this.$emit('topbar-submenuitem-click', event);
		},
		onMenuItemClick(event, item) {
			if (item.disabled) {
				event.preventDefault();
				return;
			}

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
			}

            if (item.items && this.topbarMenuActive) {
                event.preventDefault();
            }
			else {
				this.$emit('topbar-menu-click', event);
				event.preventDefault();
			}
		}
	},
	components: {
		// AppTopbarMenu
	},
    mounted() {
        this.getUser();
    }
}
</script>
<style scoped lang="scss">
    .app-logo {
        width: 275px !important;
    }
    .layout-topbar .layout-topbar-right .layout-topbar-actions li.profile-item .profile-container i {
        color: #3e4c59;
    }
    .layout-topbar .layout-topbar-right .layout-topbar-actions li.profile-item .profile-container a {
        background: inherit;
    }
    @media screen and (max-width: 991px) {
        .layout-wrapper .layout-topbar {
            flex-wrap: inherit;
        }
    }
</style>