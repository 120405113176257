<template>
	<div class="layout-footer">
    <div class="footer-left">
        <a href="https://www.optakit.com" target="_new"><img :src="'layout/images/logo-Optakit' + (layoutColor === 'white' ? 'Black' : 'White') + '.png'" class="optakit-logo" alt="Optakit Inc" /></a>
    </div>
    <div class="footer-right">
        <span>©Optakit</span>
    </div>
</div>

</template>

<script>
	export default {
		name: "AppFooter",
		props: ['layoutColor']
	}
</script>

<style scoped lang="scss">
    .optakit-logo {
        width: 15% !important;
    }
    @media screen and (max-width: 991px) {
        .optakit-logo {
            width: 20% !important;
        }
    }
</style>