import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import { fireauth } from "@/firebase";
import { onAuthStateChanged, getIdTokenResult } from "firebase/auth";

const routes = [
	{
		path: '/',
		name: 'app',
		component: App,
		children: [
			{
				path: '',
				name: 'dashboard',
				exact: true,
				component: () => import('./components/Dashboard.vue'),
                meta: {
                    requiresAuth: true
                }
			},
            {
				path: '/settings',
				name: 'settings',
				exact: true,
				component: () => import('./components/Settings.vue'),
                meta: {
                    requiresAuth: true
                }
			},
            // {
			// 	path: '/admin',
			// 	name: 'admin',
			// 	component: () => import('./components/Admin.vue'),
            //     meta: {
            //         requiresAuth: true,
            //         requiresStaff: true,
            //     }
			// },
			{
				path: '/login',
				name: 'login',
				component: () => import('./pages/Login.vue'),
                meta: {
                    requiresAuth: false
                  }
			},
			{
				path: '/error',
				name: 'error',
				component: () => import('./pages/Error.vue'),
                meta: {
                    requiresAuth: true
                }
			},
            {
				path: '/insight',
				name: 'insight',
				component: () => import('./components/Table.vue'),
                meta: {
                    requiresAuth: true
                }
			},
			{
				path: '/access',
				name: 'access',
				component: () => import('./pages/Access.vue'),
                meta: {
                    requiresAuth: true
                }
			},
		]
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/Table.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
        meta: {
            requiresAuth: false
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

// function currentUser() {
//     return new Promise((resolve, reject) => {
//         const unsubscribe = onAuthStateChanged(fireauth, (user) => {
//             unsubscribe();
//             resolve(user);
//         }, reject);
//       }
//     );
// }

function currentStaff() {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(fireauth, async (user) => {
            if (user) {
                let staff = await getIdTokenResult(user);
                if (user) unsubscribe(); resolve(staff.claims.staff !== undefined ? true : false);
            }
            else { resolve(false); }
        }, reject);
      }
    );
}

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresStaff = to.matched.some(record => record.meta.requiresStaff);
    if (requiresStaff && !await currentStaff()){
        next('access');
    }else if (requiresAuth && !await currentStaff()){
      next('access');
    }else{
      next();
    }
  });

export default router;