
<template>
	<div :class="containerClass" @click="onDocumentClick">
		<AppTopBar :layoutColor="layoutColor" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive"
			@menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick" @topbar-submenu-click="onTopbarSubmenuClick"
			@menuitem-click="onMenuItemClick" @topbar-item-click="onTopbarItemClick" @topbar-menuitem-click="onTopbarMenuItemClick" @search-item-click="onSearchItemClick"></AppTopBar>

		<!-- <AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
			@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu> -->

		<div class="layout-content-wrapper">
			<div class="layout-content">
				<router-view/>
			</div>
			
			<AppFooter :layoutColor="layoutColor" />

		</div>

		<!-- <AppConfig :menuMode="menuMode" :layoutColor="layoutColor" :lightMenu="lightMenu" :theme="theme" :themes="themeColors"
			@menu-mode-change="onMenuModeChange" @color-scheme-change="onColorSchemeChange" @layout-color-change="onLayoutColorChange"
			@theme-change="onThemeChange"></AppConfig> -->
	</div>
    <Toast position="bottom-right" />
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
// import AppConfig from "./AppConfig";

export default {
	data() {
		return {
			menuMode: 'slim',
			theme: 'indigo',
			layoutColor: 'white',
			tempLayoutColor: 'white',
			lightMenu: true,
			staticMenuMobileActive: false,
			staticMenuDesktopInactive: false,
			menuClick: false,
			topbarMenuClick: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			menuActive: false,
			searchClick: false,
			themeColors: [
				{name: "Blue", file: "blue", color: "#0f97c7"},
				{name: "Blue Grey", file: "bluegrey", color: "#578697"},
				{name: "Brown", file: "brown", color: "#97664A"},
				{name: "Cyan", file: "cyan", color: "#1BA7AF"},
				{name: "Deep Orange", file: "deeporange", color: "#F96F43"},
				{name: "Deep Purple", file: "deeppurple", color: "#6952EC"},
				{name: "Green", file: "green", color: "#10B163"},
				{name: "Teal", file: "teal", color: "#4EA279"},
				{name: "Indigo", file: "indigo", color: "#435AD8"},
				{name: "Lime", file: "lime", color: "#A5B600"},
				{name: "Magenta", file: "magenta", color: "#B944D6"},
				{name: "Orange", file: "orange", color: "#E2841A"},
				{name: "Pink", file: "pink", color: "#E93A76"},
				{name: "Purple", file: "purple", color: "#9643F9"},
				{name: "Rose", file: "rose", color: "#AB5353"},
			],
			menu: [
				{
					label: 'Dashboard', icon: 'pi pi-home',
					items: [
						{label: 'Sales', icon: 'pi pi-desktop', to: '/'},
						{label: 'Analytics', icon: 'pi pi-chart-bar', to: '/analytics'},
					],
				},
				{
					label: 'UI Kit', icon: 'pi pi-star',
					items: [
						{label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout'},
						{label: 'Input', icon: 'pi pi-check-square', to: '/input'},
						{label: 'Float Label', icon: 'pi pi-bookmark', to: '/floatlabel'},
						{label: 'Invalid State', icon: 'pi pi-exclamation-circle', to: '/invalidstate'},
						{label: 'Button', icon: 'pi pi-mobile', to: '/button', class: 'rotated-icon'},
						{label: 'Table', icon: 'pi pi-table', to: '/table'},
						{label: 'List', icon: 'pi pi-list', to: '/list'},
						{label: 'Tree', icon: 'pi pi-share-alt', to: '/tree'},
						{label: 'Panel', icon: 'pi pi-tablet', to: '/panel'},
						{label: 'Overlay', icon: 'pi pi-clone', to: '/overlay'},
						{label: 'Media', icon: 'pi pi-image', to: '/media'},
						{label: 'Menu', icon: 'pi pi-bars', to: '/menu'},
						{label: 'Message', icon: 'pi pi-comment', to: '/messages'},
						{label: 'File', icon: 'pi pi-file', to: '/file'},
						{label: 'Chart', icon: 'pi pi-chart-bar', to: '/chart'},
						{label: 'Misc', icon: 'pi pi-circle-off', to: '/misc'},
					]
				},
				{
					label: 'Prime Blocks', icon: 'pi pi-prime',
					items: [
						{label: 'Free Blocks', icon: 'pi pi-eye', to: '/blocks'},
						{label: 'All Blocks', icon: 'pi pi-globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank'}
					]
				},
				{
					label: 'Utilities', icon:'pi pi-compass',
					items: [
						{label: 'PrimeIcons', icon:'pi pi-prime', to:'/icons'},
						{label: 'PrimeFlex', icon:'pi pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank'}
					]
				},
				{
					label: 'Pages', icon: 'pi pi-briefcase',
					items: [
						{label: 'Crud', icon: 'pi pi-pencil', to: '/crud'},
						{label: 'Calendar', icon: 'pi pi-calendar-plus', to: '/calendar'},
						{label: 'Timeline', icon: 'pi pi-calendar', to: '/timeline'},
						{label: 'Landing', icon: 'pi pi-globe', url: 'pages/landing.html', target: '_blank'},
						{label: 'Login', icon: 'pi pi-sign-in', to: '/login'},
						{label: 'Invoice', icon: 'pi pi-dollar', to: '/invoice'},
						{label: 'Help', icon: 'pi pi-question-circle', to: '/help'},
						{label: 'Error', icon: 'pi pi-times-circle', to: '/error'},
						{label: 'Not Found', icon: 'pi pi-exclamation-circle', to: '/notfound'},
						{label: 'Access Denied', icon: 'pi pi-lock', to: '/access'},
						{label: 'Empty', icon: 'pi pi-circle-off', to: '/empty'}
					]
				},
				{
					label: 'Hierarchy', icon: 'pi pi-align-left',
					items: [
						{
							label: 'Submenu 1', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 1.1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.1.2', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.1.3', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 1.2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.2.1', icon: 'pi pi-align-left'}
									]
								},
							]
						},
						{
							label: 'Submenu 2', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 2.1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 2.1.2', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 2.2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.2.1', icon: 'pi pi-align-left'},
									]
								},
							]
						}
					]
				},
				{
					label: 'Start', icon: 'pi pi-download',
					items: [
						{
							label: 'Buy Now',
							icon: 'pi pi-shopping-cart',
							command: () => window.open('https://www.primefaces.org/store', '_blank')
						},
						{label: 'Documentation', icon: 'pi pi-file', to: '/documentation'},
					]
				}
			]
		}
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		}
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick && !this.searchClick) {
				this.activeTopbarItem = null;
			}

			if (!this.topbarMenuClick) {
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isSlim()) {
					this.menuActive = false;
                }

				if (this.staticMenuMobileActive) {
					this.hideOverlayMenu();
				}

				EventBus.emit('reset-active-index');
				this.unblockBodyScroll();
			}

			this.searchClick = false;
			this.topbarItemClick = false;
			this.topbarMenuClick = false;
			this.menuClick = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.isDesktop())
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
				if (this.staticMenuMobileActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;
			

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;
			
			event.originalEvent.preventDefault();
		},
		onSearchItemClick() {
			this.searchClick = true;
		},
		onTopbarMenuClick() {
			this.topbarMenuClick = true;
			this.topbarMenuActive = true;
		},
		onTopbarSubmenuClick(event) {
			this.topbarMenuClick = true;

			if (!event.item.items) {
				this.topbarMenuActive = false;
				EventBus.emit('reset-active-index');
			}
		},
		onTopbarMenuItemClick(event) {
			this.topbarItemClick = true;
			
			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;
			
			event.originalEvent.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isSlim()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		isTablet() {
			const width = window.innerWidth;
			return width <= 1024 && width > 640;
		},
		isDesktop() {
			return window.innerWidth > 896;
		},
		isMobile() {
			return window.innerWidth < 1025;
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.staticMenuDesktopInactive = false;
		},
		onThemeChange(theme) {
			this.theme = theme;

			const themeLink = document.getElementById('theme-css');
			const href = 'theme/' + theme + '/theme-' + this.$appState.colorScheme + '.css';

			this.replaceLink(themeLink, href);
		},
		onLayoutColorChange(layout) {
			this.tempLayoutColor = layout;
			this.layoutColor = layout;

			this.changeStyleSheetUrl('layout-css', layout, 'layout');
		},
		onColorSchemeChange(newColorScheme) {
			this.$appState.colorScheme = newColorScheme;
			this.layoutColor = newColorScheme === 'dark' ? newColorScheme : this.tempLayoutColor;

			this.changeStyleSheetUrl('layout-css', this.layoutColor, 'layout');
			this.changeStyleSheetUrl('theme-css', newColorScheme, 'theme');
		},
		changeStyleSheetUrl(id, value, prefix) {
			let element = document.getElementById(id);
			let urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
			let newURL = urlTokens.join('/');

			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		},
		blockBodyScroll() {
			this.addClass(document.body, 'blocked-scroll');
		},
		unblockBodyScroll() {
			this.removeClass(document.body, 'blocked-scroll');
		},
		hideOverlayMenu() {
			this.staticMenuMobileActive = false;
		},
		addClass(element, className) {
			if (element.classList)
				element.classList.add(className);
			else
				element.className += ' ' + className;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-slim': this.menuMode === 'slim',
				'layout-static': this.menuMode === 'static',
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
		}
	},
	components: {
		'AppTopBar': AppTopBar,
		// 'AppMenu': AppMenu,
		// 'AppConfig': AppConfig,
		'AppFooter': AppFooter
	}
}
</script>

<style lang="scss">
    @import 'App.scss';
    .layout-wrapper.layout-slim .layout-content-wrapper {
        width: 100%;
        margin-left: inherit;
    }
</style>
